import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './EditCompanyData.scss'
import useApi from '../../../lib/hooks/useAPI'
import Loader from '../../common/Loader'
import FlatBtn from '../../common/FlatBtn'
import { Selector } from '../../common/Selector'
import { GiModernCity } from 'react-icons/gi'
import { MdOutlineDescription, MdOutlineFastfood, MdPerson } from 'react-icons/md'
import { ImgInput } from '../../common/ImgInput'
import { getCategories } from '../../../Services/Utils/getCategories'
import ActionButton from '../../common/ActionButton'
import SuccessResult from '../../common/SuccessResult'
import { useTranslation } from 'react-i18next'

export const EditCompanyData = () => {

  const api = useApi()

  const { companyId } = useParams()

  const [companyData, setCompanyData] = useState(null)
  const [citiesAndBranches, setCitiesAndBranches] = useState(null)
  const [city, setCity] = useState(null)
  const [branch, setBranch] = useState(null)
  const [name, setName] = useState(null)
  const [category, setCategory] = useState(null)
  const [address, setAddress] = useState(null)
  const [notifyManagers, setNotifyManagers] = useState(true)
  const [billPay, setBillPay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const [defaultWaiter,setDefaultWaiter] = useState(null)
  const [waiterList,setWaiterList]  = useState([])

  useEffect(() => {
    console.log(notifyManagers)
    getData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoading(true)
    const companyGetRes = await api.get('company.get', { companyId: companyId })
    if (!companyGetRes.success || !companyGetRes.data.companies) {
      setLoading(false)
      return
    }
    const emplyeeGetRes = await api.get("user.get", {
      companyId: companyId,
    });
    if (emplyeeGetRes.success && emplyeeGetRes.data.users) {
      setWaiterList(emplyeeGetRes.data.users.map((u) => ({id: u.id, name: u.surname + ' ' + u.name})));
    }

    const listGetRes = await api.get('list.get')
    if (!listGetRes.success) {
      setLoading(false)
      return
    }
    setDefaultWaiter(companyGetRes.data['companies'].filter(company => company.id === companyId)[0]?.defaultWaiter)
    setCitiesAndBranches(listGetRes.data)
    setCompanyData(companyGetRes.data['companies'].filter(company => company.id === companyId)[0])
    setName(companyGetRes.data['companies'].filter(company => company.id === companyId)[0]?.name)
    setCity(companyGetRes.data['companies'].filter(company => company.id === companyId)[0]?.cityId)
    setBranch(companyGetRes.data['companies'].filter(company => company.id === companyId)[0]?.branchId)
    setCategory(companyGetRes.data['companies'].filter(company => company.id === companyId)[0]?.category)
    setNotifyManagers(companyGetRes.data['companies'].filter(company => company.id === companyId)[0]?.notifyManagers)
    setBillPay(companyGetRes.data['companies'].filter(company => company.id === companyId)[0]?.billPay)
    setLoading(false)
  }

  const { t } = useTranslation()

  const getCompanyEditParam = () => {
    return {
      companyId,
      name,
      category,
      cityId: city,
      branchId: branch,
      address: address,
      notifyManagers: notifyManagers,
      billPay: billPay,
      defaultWaiterID: defaultWaiter
    }
  }

  const companyUpdate = async () => {
    setLoading(true)
    console.log(getCompanyEditParam())
    const res = await api.post('company.update', getCompanyEditParam())
    if (!res.success) {
      setLoading(false)
      return
    }
    setLoading(false)
    setShowSuccess(true)
    setTimeout(() => window.location.replace(`/company/${ companyId }`), 1000)
  }

  return (<div className="admin central noselect">
      <div className="app-container admin__app-container">
        <div className="admin__header">
          <Link to={ '/company/' + companyId }>
            <FlatBtn type="prev"/>
          </Link>
          <p className="header-text">{ companyData?.name }</p>
        </div>
        <div className="admin__center">
          { !loading && showSuccess && <SuccessResult text={ t('success') }/> }
          { loading && !showSuccess && <div className="d-flex flex-column justify-content-center flex-grow-1">
            <Loader type="dot"/>
          </div> }
          { !loading && !showSuccess && <>
            <div className="company">
              <Selector
                value={ city }
                onChangeHandler={ setCity }
                optionPlaceholder={ t('city') }
                loading={ loading }
                contentArray={ citiesAndBranches?.cities }
                icon={ <GiModernCity/> }
              />
              <Selector
                value={ branch }
                onChangeHandler={ setBranch }
                optionPlaceholder={ t('department') }
                loading={ loading }
                contentArray={ citiesAndBranches?.branches }
                icon={ <MdOutlineFastfood/> }
              />
              <Selector
                value={ defaultWaiter }
                onChangeHandler={ setDefaultWaiter }
                optionPlaceholder={ t('defaultWaiter') }
                loading={ loading }
                contentArray={ waiterList }
                icon={ <MdPerson/> }
              />
              <div className="input__item">
                <ImgInput
                  imageType="bag"
                  placeholder={ t('companyName') }
                  onChange={ e => setName(e.target.value) }
                  field={ name ? name : '' }
                />
              </div>
              <div className="input__item">
                <ImgInput
                  imageType="bag"
                  placeholder={ t('address') }
                  onChange={ e => setAddress(e.target.value) }
                  field={ address ? address : '' }
                />
              </div>
              <Selector
                value={ category }
                onChangeHandler={ setCategory }
                optionPlaceholder={ t('category') }
                loading={ loading }
                contentArray={ getCategories() }
                icon={ <MdOutlineDescription/> }
              />
              <div className="input__item d-flex justify-content-between">
                <div className="">{ t('notifyManagers') }</div>
                <input checked={ notifyManagers }
                       onClick={ () => {
                         setNotifyManagers(!notifyManagers)
                       } }
                       onChange={ () => {} }
                       type="checkbox"
                />
              </div>
              <div className="input__item d-flex justify-content-between">
                <div className="">{ t('billPay') }</div>
                <input checked={ billPay }
                       onClick={ () => {
                         setBillPay(!billPay)
                       } }
                       onChange={ () => {} }
                       type="checkbox"
                />
              </div>
            </div>

          </> }
        </div>
        { !showSuccess && <div className="admin__footer">
          <ActionButton
            onClick={ companyUpdate }
            text={ t('editCompanyData') }
            loader={ loading }
            disabled={ !name }
          />
        </div> }
      </div>
    </div>)
}
