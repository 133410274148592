import React from "react";
import { ReactComponent as ImgStarActive } from "../img/star_active.svg";
import config from "../../../config";
import { AuthContext } from "../../../lib/auth/Context";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Transaction.module.scss";
import atmospherePositive from "../../../Assets/WEBP/atmospherePositive.webp";
import atmosphereNegative from "../../../Assets/WEBP/atmosphereNegative.webp";
import cuisinePositive from "../../../Assets/WEBP/cuisinePositive.webp";
import cuisineNegative from "../../../Assets/WEBP/cuisineNegative.webp";
import servicePositive from "../../../Assets/WEBP/servicePositive.webp";
import serviceNegative from "../../../Assets/WEBP/serviceNegative.webp";

export const Transaction = React.memo(
  ({ lines, transaction, isAdmin = false }) => {
    const { state } = useContext(AuthContext);

    const canShow = () => {
      return state.rights === config.rights.root;
    };

    const { t } = useTranslation();

    const dateFormat = (createdAt) => {
      const date = new Date(createdAt);
      const minutes =
        String(date.getMinutes()).length === 1
          ? `0${date.getMinutes()}`
          : date.getMinutes();
      return date.getHours() + ":" + minutes;
    };

    const getBackground = (quickComment) => {
      if (quickComment === "atmospherePositive") {
        return atmospherePositive;
      } else if (quickComment === "atmosphereNegative") {
        return atmosphereNegative;
      } else if (quickComment === "cuisinePositive") {
        return cuisinePositive;
      } else if (quickComment === "cuisineNegative") {
        return cuisineNegative;
      } else if (quickComment === "servicePositive") {
        return servicePositive;
      } else if (quickComment === "serviceNegative") {
        return serviceNegative;
      } else {
        return "";
      }
    };
    console.log({transaction})
    return (
      <div className={styles["wrapper"]}>
        {lines[transaction.id] && (
          <div className={styles["date-row"]}>
            <p>{lines[transaction.id].day}</p>
            {isAdmin && (
              <>
                {canShow() && (
                  <p>
                    {`${t("totalPerDay")}: ${lines[transaction.id].sum}${t(
                      "KZT"
                    )}`}
                  </p>
                )}
              </>
            )}
          </div>
        )}
        <div className={styles["row"]}>
          <div className={styles["date-tr-type"]}>
            <div className={styles["date"]}>
              {dateFormat(transaction.createdAt)}
            </div>
            {transaction.type === 1 && (
              <div className={`${styles["transaction-type"]} green-text`}>
                {transaction.kind===3?t('bonus'):t("receipt")}
              </div>
            )}
            {transaction.type === 2 && (
              <div className={`${styles["transaction-type"]} gray-text`}>
                Вывод
              </div>
            )}
          </div>
          <div className={styles["table-amount"]}>
            {transaction.businessData && transaction.businessData.tableNumber && (
              <div className={styles["transaction-table"]}>
                <span className={styles["gray-text"]}>{`${t("table")}:`}</span>
                {transaction.businessData &&
                  transaction.businessData.tableNumber}
              </div>
            )}
            {transaction.amount > 0 && transaction.amountOnHand > 0 &&
              <div className="transaction-amount">
                {transaction.kind === 0 ? `${transaction.amountOnHand} ₸` : `${transaction.amount} ₸` }
              </div>
            }
          </div>
        </div>
        <div className={styles["row"]}>
          <div>
            {transaction.businessData &&
              transaction.businessData.estimate > 0 &&
              [...Array(transaction.businessData.estimate)].map((x, i) => (
                <ImgStarActive key={i} width="10" height="10" />
              ))}
          </div>
          <div className={styles["quick-comments"]}>
            {transaction.businessData &&
              transaction.businessData?.quickComment?.map((qc, i) => (
                <div
                  key={qc}
                  style={{
                    backgroundImage: `url(${getBackground(qc)})`,
                  }}
                  className={styles["quick-comment"]}
                />
              ))}
          </div>
        </div>
        {!isAdmin && (
          <div className={styles["row"]}>
            <div>
              {transaction.businessData && transaction.businessData.companyName}
            </div>
          </div>
        )}
        {isAdmin && (
          <div className={styles["row"]}>
            <div>{transaction.userName}</div>
          </div>
        )}
        <div className={styles["row"]}>
          <div className={`${styles["comment"]} ${styles["green-text"]}`}>
            {transaction.businessData && transaction.businessData.comment}
          </div>
        </div>
      </div>
    );
  }
);
