import React, {useState, useEffect, useContext} from 'react'
import {Link, useParams} from 'react-router-dom'
import useAPI from '../../lib/hooks/useAPI'
import FlatBtn from '../common/FlatBtn'

import './CompanyDetail.scss'
import {ImgButton} from '../common/ImgButton'
import {AuthContext} from '../../lib/auth/Context'
import Loader from '../common/Loader'
import {getNamedMonth} from '../../lib/helpers/time'
import config from '../../config'
import {FiEdit} from 'react-icons/fi'
import {BsPeople, BsPerson, BsPersonPlus} from 'react-icons/bs'
import {useTranslation} from 'react-i18next'
import {List} from '../common/List'
import {getTransactions} from '../../Services/Utils/getTransactions'
import {LoadMore} from '../common/LoadMore/index'
import {useAvatar} from '../../Services/Hooks/useAvatar/index'
import {AvatarLargeCircle} from '../common/AvatarCircle'
import ReactCrop from 'react-image-crop'
import ActionButton from '../common/ActionButton'

export default function CompanyDetail() {
  const api = useAPI()

  const {state} = useContext(AuthContext)

  const {companyId} = useParams()

  const [company, setCompany] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [loadTransactions, setLoadTransactions] = useState(false)
  const [lines, setLines] = useState(null)
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [showTipsTx, setShowTipsTx] = useState(false)

  let getTxMethodName = showTipsTx ? "company.getTipTransactions" : "company.getBillTransactions"
  console.log(company)
  useEffect(() => {
    getCompany()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const requestParams = (companyId, p, l) => ({
    companyId: companyId, p: p, l: l,
  })

  const clickHandler = () => setPage((pv) => {
    getTransactions(api, getTxMethodName, requestParams(companyId, pv + 1, 30), setLoadTransactions, separateToLines, setTransactions, setHasMore)
    return pv + 1
  })

  const {t} = useTranslation()

  let hiddenFile = null

  const {
    onPhotoChangeClick, onImageLoaded, onCropComplete, onCropChange, uploadFile, progress, src, crop, imgLoading,setSrc
  } = useAvatar(null, companyId, null, setCompany, company, true)
  
  const getCompany = async () => {
    const res = await api.get('company.get', {companyId: companyId})
    if (!res.success || !res.data.companies) {
      return
    }
    setCompany(res.data.companies[0])
    setIsLoaded(true)
    if (res.data.companies[0].billPay) {
      setShowTipsTx(false)
      setLoadTransactions(true)
      getTransactions(api, getTxMethodName, requestParams(companyId, page, 30), setLoadTransactions, separateToLines, setTransactions, setHasMore)
    } else {
      setShowTipsTx(true)
    }
  }

  const separateToLines = (transactions) => {
    const lines = {}
    let transactionDateLine
    let lastID
    transactions.forEach((t) => {
      const date = new Date(t.createdAt)
      const today = new Date()
      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)

      let day

      // if (date.getDay() === today.getDay()) {
      //     day = "Сегодня";
      // } else if (date.getDay() === yesterday.getDay()) {
      //     day = "Вчера";
      // } else {
      day = date.getDate() + ' ' + getNamedMonth(date.getMonth())
      // }

      if (transactionDateLine !== day) {
        transactionDateLine = day
        lastID = t.id
        lines[lastID] = {day: day, sum: 0}
        if (t.type === 1) {
          lines[lastID].sum = t.kind === 0 ? t.amountOnHand : t.amount
        }
      } else {
        if (t.type === 1) {
          lines[lastID].sum += t.kind === 0 ? t.amountOnHand : t.amount
        }
      }
    })

    setLines(lines)
  }

  useEffect(() => {
    if (!isLoaded) {
      return
    }
    setLoadTransactions(true)
    setTransactions([])
    getTransactions(api, getTxMethodName, requestParams(companyId, page, 30), setLoadTransactions, separateToLines, setTransactions, setHasMore)
  }, [showTipsTx])

  useEffect(() => {
    if (!company) return
    console.log(company.photo)
  }, [company])

  return (<div className="admin central" style={{maxHeight: '100vh', maxWidth: '100vw', overflowY: 'auto'}}>
    <div className="app-container admin__app-container" id={'companyDetailListID'}>
      <div className="admin__header">
        <Link onClick={(e) => {
          if (src) {
            e.preventDefault()
            e.stopPropagation()
            setSrc(null)
          }
        }} to="/company">
          <FlatBtn type="prev"/>
        </Link>
        <p className="header-text">{company?.name}</p>
      </div>
      {!isLoaded && (<div className="d-flex flex-column justify-content-center flex-grow-1">
        <Loader type="dot"/>
      </div>)}
      {company && (<div className="company-profile">
        <div className="profile__avatar">
          {!src && company  && (<AvatarLargeCircle photo={company ? company?.photo : null} onClickHandler={() => hiddenFile.click()}/>)}
          {company && (<>
            <input style={{display: 'none'}} type="file" ref={(input) => {
              hiddenFile = input
            }} onChange={onPhotoChangeClick}/>
            {imgLoading && <Loader type="dot"/>}
          </>)}
          {src && (<>
            <div className="profile__crop">
              <ReactCrop src={src} crop={crop} ruleOfThirds onImageLoaded={onImageLoaded} onComplete={onCropComplete} onChange={onCropChange}/>
            </div>
            {progress < 100 && (<ActionButton text={progress ? progress + '%' : t('save')} onClick={() => uploadFile()}/>)}
            {progress >= 100 && <Loader type="dot"/>}
          </>)}
        </div>
        
        {!src && <div className="company-profile__name">{company.name}</div>}
        <br/>
        {!src&& <div className="between company-profile__info-item">
          <b>id:</b> &nbsp; &nbsp; &nbsp;
          <p>{company.number}</p>
        </div>}
        {!src && <div className="between company-profile__info-item">
          <b>external_Id:</b> &nbsp; &nbsp; &nbsp;
          <p>{company.externalId}</p>
        </div>}
        {!src&&<div className="company-profile__actions">
          <Link to={'/company/' + companyId + '/employee'}>
            <div className="company-profile__action-item">
              <ImgButton image={<BsPeople/>} text={t('employees')}/>
            </div>
          </Link>
          <Link to={'/company/' + companyId + '/managers'}>
            <div className="company-profile__action-item">
              <ImgButton image={<BsPerson/>} text={t('managers')}/>
            </div>
          </Link>
          {state.rights === config.rights.root && (<Link to={'/company/' + companyId + '/edit'}>
            <div className="company-profile__action-item">
              <ImgButton image={<FiEdit/>} text={t('editCompanyData')}/>
            </div>
          </Link>)}
          {state.rights === config.rights.root && (<Link to={'/company/' + companyId + '/registration'}>
            <div className="company-profile__action-item">
              <ImgButton image={<BsPersonPlus/>} text={t('registration')}/>
            </div>
          </Link>)}
          {state.rights === config.rights.root && (<Link to={'/company/' + companyId + '/accounts'}>
            <div className="company-profile__action-item">
              <ImgButton image={<FiEdit/>} text={t('companyAccounts')}/>
            </div>
          </Link>)}
        </div>}
        {!src &&<div className="company-transactions">
          {isLoaded && company.billPay && <div className="company-transactions__switcher">
            <div className={!showTipsTx ? "transaction__switch-button" : "transaction__switch-button transaction__switch-button_disabled"} onClick={()=>setShowTipsTx(false)}>Счета</div>
            <div className={showTipsTx ? "transaction__switch-button" : "transaction__switch-button transaction__switch-button_disabled"} onClick={()=>setShowTipsTx(true)}>Чаевые</div>
          </div>}
          {loadTransactions && transactions.length === 0 && (<div className="d-flex flex-grow-1">
            <Loader type="dot"/>
          </div>)}
          {!loadTransactions && transactions.length === 0 && (<div className="d-flex flex-grow-1 align-items-center justify-content-center">
            {t('noReceipts')}
          </div>)}
          {transactions.length > 0 && lines && (
            <List type={'transactions'} list={transactions} id={'companyDetailListID'} scrollHandler={() => console.log()}
              renderCondition={transactions.length > 0} listItemProps={{
              lines: lines, isAdmin: true,
            }} hasMore={hasMore}/>)}
          <LoadMore renderCondition={transactions.length > 0 && lines && hasMore} clickHandler={clickHandler} loadingCondition={loadTransactions}
            buttonText={t('loadMore')} fullListText={''}/>
        </div>}
      </div>)}
    </div>
  </div>)
}
